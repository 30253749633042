<template>
  <div class="container-results-proposals mx-auto" >
    <b-col class="filters-fixed-nav" ref="filters_fixed">
      <div class="row max-width-1400">
        <div class="col-12 col-md-3 col-lg-2 p-0 mb-1 mb-md-0 d-flex justify-content-around border-right">
          <b-button 
            variant="button-filters" 
            pill 
            :class="`button-filters position-relative ${filters_used_number > 0 ? 'justify-content-between' : 'justify-content-center'}`" 
            @click="open_modal = true; update_modal = !update_modal;">
            <div>
              <b-icon icon="sliders"></b-icon> 
              {{$t('search.filters')}} 
            </div>
            <div class="badge-number-filters-used" v-if="filters_used_number > 0">{{filters_used_number}}</div>
          </b-button>
          <b-button pill class="button-filters button-trash-icon" variant="button-filters button-trash-icon" @click="clearFilters">
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </div>
        <component :is="vue_horizontal" responsive class="horizontal col-12 col-md-9 col-lg-10 d-flex align-items-center" :displacement="0.3" snap="center">
          <b-badge 
            pill 
            variant="outline-secondary-light" 
            :class="`outline-secondary-light ${isActiveClass(button.value)}`" 
            v-for="(button, index) in $t('creator.type_of_service')" 
            :key="index"
            @click="addContentTypeFilter(button.value)"
          >{{button.text}}</b-badge>
        </component>
      </div>
    </b-col>
    <b-col class="d-flex flex-wrap min-height-500 text-campaign-custom">
      <div v-if="casting_calls.length > 0" class="col-12 mb-2">
        <strong class="w-100 h3 d-block">{{$t('creator.totalCasting')}}</strong>
        <span class="h5" v-if="$route.query.query">
        {{$t('creator.searchBy')}} "{{$route.query.query}}"
        </span>
      </div>
      <div v-for="(casting_call) in castingFiltered" :key="casting_call.uuid" class="col-25 col-12 col-md-6 col-lg-3 col-sm-6 responsive-xs">
        <card-casting-call class="mb-2" :casting_call="casting_call"></card-casting-call>
      </div>
      <b-col class="empty-state-container-proposals" v-if="casting_calls.length === 0 && !loader_results">
        <no-Results 
          :title="`${$t('creator.noResults')} ${$route.query.query ? $route.query.query : ''}`"
          :subtitle="$t('creator.textFilters')"
          :text_button="$t('creator.backCampaign')"
          :btn_action="clearFilters"
          :show_icon="false"
          :class_btn="'blue-button mt-1'"
        />

      </b-col>
      <div class="container-loader-searcher loader-table-cards" v-if="loader_results">
        <b-spinner class="spinner-table-searcher"></b-spinner>
      </div>
      <pagination-brandme 
        class="col-12 d-flex justify-content-center mt-2"
        :total_rows="casting_calls.length"
        :per_page="per_page"
        :current_page="current_page"
        @update_current_page="updateCurrentPage"
      ></pagination-brandme>
      <discover-creator-casting
        class="col-12 p-0"
        :img="getUrlCategory('home_casting_call')"
        :textInfo="$t('creator.letBrand')"
        :subtitle="$t('creator.inMinutes')"
        :textButton="$t('creator.createListing')"
        :btn_action="redirectOption"
        :disabled_btn="false"
      />
    </b-col>

    <filter-search-casting-call 
      :open_modal="open_modal" 
      :key="update_modal"
      :filters_layout="filters_layout"
      @filter="filterAction"
    ></filter-search-casting-call >
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import casting_calls_services from '@/services/casting_calls';
import { getUrlCategory } from '@/libs/utils/urls'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getUserData } from '@/libs/utils/user';

export default {
  name: 'castingCalls',
  components: {
    BRow,
    BCol,
    BSpinner,
    BButton,
    BBadge,
    cardCastingCall: () => import('@/views/components/casting-calls/cardCastingCall.vue'),
    discoverCreatorCasting: () => import('@/views/components/proposals/cardCastingCall.vue'),
    filterSearchCastingCall: () => import('@/views/components/casting-calls/filterSearchCastingCall.vue'),
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    paginationBrandme: () => import('@/views/components/pagination/PaginationBrandme.vue')
  },
  data() {
    return {
      create_proposal: false,
      getUrlCategory,
      casting_calls: [],
      outside_buttons_filter: [
        {value: 'work_for_hire', text: 'Work for Hire'},
        {value: 'job_listing', text: 'Job Listing'},
        {value: 'influencer_marketing', text: 'Influencer Marketing'},
      ],
      content_types: [],
      current_page: 1,
      filters_used_number: 0,
      per_page: 10,
      query: undefined,
      open_modal: false,
      update_modal: false,
      loader_results: false,
      filters_layout: [],
      filters: {},
      loaded: false,
      changFilters: false,
      filter_button_selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
      vue_horizontal: null,
    }
  },
  computed: {
    castingFiltered () {
      const items = this.casting_calls;
      return items.length > 0 ? items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page) : 0;
    },
  },
  created() {
    const user = getUserData()

    if (user) {
      this.create_proposal = user.meta_info
    }
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "white";
    this.$root.$on('update_casting_calls', (selected_button) => {
      if (selected_button) this.filter_button_selected = selected_button;
      this.addFiltersFromParams();
      this.loader_results = true;
      this.getCastingCalls();
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
    });
    this.addFiltersFromParams();
    this.loader_results = true;
    this.getCastingCalls();
    setTimeout(() => {
      document.getElementsByClassName('filters-fixed-nav')[0].style.display = 'block'
    }, 500)
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default;
  },
  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    body.removeAttribute('style');
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },
  methods: {
    updateCurrentPage(new_current_page) {
      this.current_page = new_current_page;
    },
    redirectOption() {
      if (this.create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
    addContentTypeFilter(content_type_value) {
      !this.content_types.find((con) => con === content_type_value) ? this.content_types.push(content_type_value) : this.content_types = this.content_types.filter((item) => item !== content_type_value);
      this.filter_button_selected.list_button = this.filters.content_types = this.content_types;
      if (this.filter_button_selected.selected === false) this.filter_button_selected.selected = true; 
      if (this.filter_button_selected.type_button === '') this.filter_button_selected.type_button = 'content_type'
      this.getCastingCalls();
      this.loader_results = true
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
    },
    addFiltersFromParams() {
      const query = {}
      if (Object.keys(this.$route.query).length > 0) {
        if (this.$route.query.content_type) {
          if (Object.keys(this.filters).length === 0) this.filters.content_types = [this.$route.query.content_type]
          else this.filters.content_types.push(this.$route.query.content_type)          
          this.content_types.push(this.$route.query.content_type);    
        } else if (this.$route.query.query) {
          query.query = this.$route.query.query
          this.filters = query;
        } else if (this.$route.query.network) {
          query.work_types = [this.$route.query.network]
          this.filters = query;
        }
      }
    },
    clearFilters(update = true) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({name: 'casting_calls'});
      }
      this.filter_button_selected.selected = false;
      this.filter_button_selected.type_button = '';
      this.filter_button_selected.list_button = []
      this.filters_layout = [];
      this.filters = {};
      this.filters_used_number = 0;
      this.content_types = [];
      this.$root.$emit('update_search_query', '');
      this.$root.$emit('update_color_button_selected', this.filter_button_selected);
      if (update) this.getCastingCalls();
      this.loader_results = true
    },
    filterAction(filters, filters_used_number, filters_layout) {
      this.open_modal = false;
      this.update_modal = !this.update_modal;
      this.filters_used_number = filters_used_number;
      this.filters_layout = filters_layout;
      this.filters = Object.assign({}, this.filters, filters);
      this.getCastingCalls();
      this.loader_results = true
    },

    getCastingCalls() {
      const query = {...this.filters}
      casting_calls_services.getCastingCalls(query).then((response) => {
        this.casting_calls = response.results;
        this.loader_results = false;
      })
    },
    isActiveClass(value) {
      return this.content_types.find((item) => item === value) ? 'active-button-network' : '';
    },
  },
}
</script>

<style scoped>
.text-campaign-custom {
  margin-top: 4em;
  color: black;
}
.col-xl-25 {
  width: 20% !important;
}
.min-height-500 {
  min-height: 500px;
}
.empty-state-container-proposals {
  margin: 11em 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.max-width-1400 {
  max-width: 1400px;
  margin: 0px auto;
}
.filters-fixed-nav {
  background-color: white;
  position: sticky;
  display: none;
  width: 100%;
  left: 0;
  top: 10.7em;
  z-index: 50;
  box-shadow: 0px 10px 5px white;
  padding: 1em 0 1em 0;
  animation: filters-fixed 600ms;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 10em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.button-filters {
  border: 1px solid #ced4da !important;
  display: flex;
  align-items: center;
  width: 130px !important;
  /* position: absolute; */
}
.button-trash-icon {
  display: block !important;
  width: 60px !important;
  cursor: pointer;
}
.button-filters:hover {
  background-color: #e8e8e8 !important;
}
.badge-number-filters-used {
  background-color: #7367F0;
  width: 20px;
  height: 20px;
  border-radius: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 1em;
}
.outline-secondary-light {
  width: 140px !important;
  height: 41px;
  border: 1px solid #ced4da !important;
  border-radius: 5em;
  color: #6c757d !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1em;
}
.outline-secondary-light:hover {
  background-color: #e8e8e8 !important;
  transition: all 300ms;
}
.outline-secondary-light > .btn {
  padding: 0.2em !important;
}
.active-button-network, .active-button-network:hover {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.active-button-filters {
  background-color: #d5ebe4 !important;
  color: #267e5e !important;
}
.container-results-proposals {
  max-width: 1440px;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0;
  margin: 0;
  padding: 8px;
  height: 100%;
  box-shadow: none;
  background: none;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white);
  padding-right: 24px;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white);
  padding-left: 24px;
}

.horizontal >>> .v-hl-btn {
  top: 0;
  bottom: 0;
  transform: translateX(0);
}
.w-auto {
  width: auto !important;
}
@media(min-width: 1360px) {
  .col-25 {
    max-width: 20% !important;
  }
}
@media(max-width: 1200px) {
  .filters-fixed-nav {
    top: 5em;
  }
}
@media(max-width: 576px) {
  .responsive-xs {
    display: flex;
    justify-content: center;
  }
}
@keyframes filters-fixed {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>